<template>
  <div>
    <Text tag="p" weight="medium" :class="$style.marginBottom8">
      {{ t('label') }}
    </Text>

    <div :class="$style.container">
      <span
        v-for="item in selectedGroupsList"
        :key="item.groupId"
        :class="$style.item"
      >
        <IconAttach :class="$style.itemIcon" />
        <Text :class="$style.itemName">{{ item.groupName }}</Text>
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { IconAttach, Text } from '@smst/ui'
import type { PropType } from 'vue'
import { computed } from 'vue'

import type { Group } from '@/api/__generated__/api.schema'
import { useComponentI18n } from '@/hooks/useComponentI18n'

import type { SelectedElements } from '../../../BatchCreate.utils'

const props = defineProps({
  groups: {
    type: Array as PropType<Group[]>,
    required: true,
  },
  selectedGroups: {
    type: Object as PropType<SelectedElements>,
    required: true,
  },
})

const t = useComponentI18n(
  'batchCreate.form.phonesSource.blocks.selectedPhones'
)

const selectedGroupsList = computed(() => {
  if (props.selectedGroups.all) {
    if (props.selectedGroups?.exclude?.length > 0) {
      return props.groups.filter(
        (group) => !props.selectedGroups.exclude.includes(group.groupId)
      )
    }

    return props.groups
  }

  return props.groups.filter((group) =>
    props.selectedGroups.include.includes(group.groupId)
  )
})
</script>

<style module>
.container {
  display: flex;

  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  row-gap: var(--gap-4);
}

.item {
  display: flex;
  align-items: center;
  max-width: 100%;
  padding: var(--gap-8) var(--gap-12);

  background-color: var(--color-bg-table-line);
  border: 1px solid var(--color-bg-heading);
  border-radius: 20px;
}

.itemIcon {
  flex: none;
  margin-right: var(--gap-8);
}

.marginBottom8 {
  margin-bottom: var(--gap-8);
}

.itemName {
  max-width: 100%;

  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
