import { computed, ref } from 'vue'
import type { ComputedRef, Ref } from 'vue'

// eslint-disable-next-line import/no-cycle
import { apiClient } from '@/api'
import type { ProfileResult } from '@/api/__generated__/api.schema'

import { FetchClient } from '../fetchClient/FetchClient'
import { getAccountType } from '../getAccountType'

type ApiDataType = { data: { data: ProfileResult } }

class Profile {
  profile: ComputedRef<ProfileResult | undefined>
  profileType: Ref<string | undefined>
  isFetching: Ref
  error: Ref
  reloadTimes: Ref<number>
  constructor() {
    this.profile = computed(() => {
      return FetchClient.getStoreData('profile', undefined)
    })
    this.profileType = ref(undefined)
    this.isFetching = ref(false)
    this.error = ref(null)
    this.reloadTimes = ref(0)
  }
  async fetchProfileData() {
    this.isFetching.value = true
    this.error.value = null
    if (this.profile.value) {
      this.isFetching.value = false
      return
    }
    try {
      await FetchClient.fetchData(
        'profile',
        apiClient.profile.summary_GET,
        this.saveProfileData
      )
      this.setProfileType()
      this.reloadTimes.value = 0
      this.error.value = null
    } catch (error) {
      // console.log('error profile', error)
      // if (this.reloadTimes.value < 3) {
      //   this.error.value = null
      //   await this.reloadProfile()
      //   this.reloadTimes.value += 1
      // } else {
      this.error.value = error
      // }
    } finally {
      this.isFetching.value = false
    }
  }
  async reloadProfile() {
    this.isFetching.value = true
    this.error.value = null
    try {
      await FetchClient.fetchData(
        'profile',
        apiClient.profile.summary_GET,
        this.saveProfileData
      )
      this.setProfileType()
      this.reloadTimes.value = 0
      this.error.value = null
    } catch (error) {
      this.error.value = error
    } finally {
      this.isFetching.value = false
    }
    return
  }
  saveProfileData(data: ApiDataType) {
    return data?.data?.data
  }
  deleteProfileData() {
    this.profileType.value = undefined
    FetchClient.deleteStoreData('profile')
  }
  getProfile() {
    return this.profile
  }
  getProfileType() {
    return this.profileType.value
  }
  setProfileType() {
    this.profileType.value = getAccountType(this.profile?.value)
  }
  getIsFetching() {
    return this.isFetching
  }
  getError() {
    return this.error
  }
}

export const ProfileData = new Profile()
