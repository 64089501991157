<template>
  <div v-if="!isPhoneSourceWithMessage" :class="[$style.root, $attrs.class]">
    <div :class="[$style.header, isSameMessages && $style.disabled]">
      <Text v-if="hasRealDraftName" size="s" color="secondary">
        {{ tSelect('title') }}
      </Text>

      <Text
        :color="draft ? 'primary' : 'secondary'"
        :title="draftNameToDisplay"
        :class="$style.overflow"
      >
        {{ draftNameToDisplay }}
      </Text>
    </div>

    <Button
      type="button"
      view="secondary"
      :disabled="isSameMessages"
      @click="isOpen = !isOpen"
    >
      {{ tSelect('button') }}
    </Button>
  </div>

  <Drawer
    :modelValue="isOpen"
    :title="tDrawer('title')"
    :closeText="tDrawer('closeText')"
    @update:modelValue="handleToggleDrawer"
  >
    <Input v-model="search" :placeholder="tDrawer('title')" />

    <ul v-if="showList" :class="$style.list">
      <li v-for="item in draftsList" :key="item.draftId" :class="$style.item">
        <Checkbox
          :modelValue="selectedDraft?.draftId === item.draftId"
          :class="$style.checkbox"
          @update:modelValue="(value: boolean) => handleCheckbox(value, item)"
        >
          {{ item.name }}
        </Checkbox>
      </li>
    </ul>

    <div v-else :class="$style.container">
      <Loader v-if="isFetching" size="m" />

      <NoResultsFound
        v-if="showEmptyResult"
        :variant="MessageType.noResult"
        :withAdvice="false"
      />

      <ApiErrorMessage :error="error" />
    </div>

    <template #buttons>
      <Button
        type="button"
        :disabled="isSubmitDisabled"
        @click="handleSubmitDraft"
      >
        {{ tDrawer('submit') }}
      </Button>

      <Button type="button" view="secondary" @click="handleClearDraft">
        {{ tDrawer('clear') }}
      </Button>

      <Button type="button" view="link" @click="handleToggleDrawer(false)">
        {{ tDrawer('cancel') }}
      </Button>
    </template>
  </Drawer>
</template>

<script lang="ts" setup>
import { Button, Checkbox, Drawer, Input, Loader, Text } from '@smst/ui'
import type { PropType } from 'vue'
import { computed, ref, watchEffect } from 'vue'
import { useQuery } from 'vue-query'

import { apiClient } from '@/api'
import type { Draft } from '@/api/__generated__/api.schema'
import ApiErrorMessage from '@/components/ApiErrorMessage.vue'
import { MessageType } from '@/components/NoResultsFound/NoResultsFound.types'
import NoResultsFound from '@/components/NoResultsFound/NoResultsFound.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import type { Channel } from '@/pages/Drafts/Drafts.utils'

const props = defineProps({
  isSameMessages: Boolean,
  draft: Object as PropType<Draft>,
  channel: {
    type: String as PropType<Channel>,
    required: true,
  },
  isPhoneSourceWithMessage: Boolean,
})

const emit = defineEmits(['update:draft'])

const tSelect = useComponentI18n('batchCreate.selectDraft')
const tDrawer = useComponentI18n('batchCreate.selectDrawer')

const search = ref<string>('')
const isOpen = ref<boolean>(false)

const hasRealDraftName = computed(() => Boolean(props.draft?.name))
const draftNameToDisplay = computed(() => props.draft?.name ?? tSelect('empty'))

const selectedDraft = ref<Draft | undefined>(props.draft)

watchEffect(() => {
  selectedDraft.value = props.draft
})
const isQueryEnabled = computed(() => isOpen.value && Boolean(search.value))

const sizeList = computed(() => {
  return isQueryEnabled.value ? 100 : 8
})

const PAGINATION = computed(() => {
  return { page: 1, size: sizeList.value }
})

const searchQuery = computed(() => ({
  filter: search.value ?? '',
  ...PAGINATION.value,
}))

const { isSuccess, isFetching, data, error } = useQuery(
  ['search-draft', searchQuery],
  () => apiClient.drafts.list_GET_BY(props.channel, searchQuery.value),
  { enabled: true }
)

const draftsList = computed<Draft[]>(() => data.value?.data.list ?? [])

const showList = computed(() => isSuccess.value && draftsList?.value?.length > 0)
const showEmptyResult = computed(
  () =>
    isSuccess.value && Boolean(search.value) && draftsList?.value?.length === 0
)

const isSelectedDraftInDraftsList = computed(() =>
  draftsList.value.some(
    (draft) => draft.draftId === selectedDraft.value?.draftId
  )
)
const isSubmitDisabled = computed(() => !isSelectedDraftInDraftsList.value)

const handleCheckbox = (checked: boolean, draft: Draft) => {
  if (checked) {
    selectedDraft.value = draft
  } else {
    selectedDraft.value = undefined
  }
}

const handleToggleDrawer = (value: boolean) => {
  isOpen.value = value
  search.value = ''
  selectedDraft.value = props.draft
}

const handleClearDraft = () => {
  emit('update:draft', undefined)
  handleToggleDrawer(false)
}

const handleSubmitDraft = () => {
  emit('update:draft', selectedDraft.value)
  handleToggleDrawer(false)
}
</script>

<style module>
.root {
  display: flex;

  gap: var(--gap-8);
}

.header {
  display: flex;
  flex: 1;
  flex-direction: column;

  gap: var(--gap-4);
  justify-content: center;
  min-width: 0;
  padding: calc(var(--gap-8) - 2px) var(--gap-12);

  border: 1px solid var(--color-stroke-form);
  border-radius: var(--border-radius-s);
}

.disabled {
  color: var(--color-text-grey);

  background: var(--color-bg-form-disabled);
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
}

.list {
  display: flex;
  flex-direction: column;
  margin: var(--gap-8) 0 0 0;
  padding: 0;

  list-style: none;

  border: 1px solid var(--color-stroke-form);
  border-radius: var(--border-radius-s);
}

.item {
  padding: 0;

  &:nth-child(odd) {
    background-color: var(--color-bg-table-line);
  }
}

.checkbox {
  width: 100%;
  padding: var(--gap-16) var(--gap-12);
}

.overflow {
  min-width: 0;

  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
