<template>
    <TeleporText 
        @modalText="modal" 
        :open="open" :title="title" 
        :text="text" :bottom="true" >
        <router-link @click="modal" to="/settings">{{ link }}</router-link>
    </TeleporText>
</template>

<script setup lang="ts">
    import TeleporText from '@/components/TeleportModal/modalTextBottom.vue'
    import { computed, onMounted, ref } from 'vue';
    import { useProfile } from '@/hooks/useProfile';
    import { useComponentI18n } from '@/hooks/useComponentI18n'
    
    const modalTimeZone = 'modalTimeZone'
    const t = useComponentI18n('timeZoneAlarm')

    const open = ref(false)
    const { profile } = useProfile()
    const systemTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const timeZoneName = computed(() => profile?.value?.timezone?.name)
    // const text = `Часовой пояс вашей системы ${systemTimeZone} и часовой пояс вашего аккаунта ${timeZoneName.value} не совпадают. Это может привести к неправильному пониманию отображеного времени в кабинете. Время в кабинете отображается в соответствии с настройками аккаунта, а не вашей системы. Пожалуйста, обновите настройки часового пояса в вашем аккаунте.`
    const text = computed(() => t('text', {systemTimeZone: systemTimeZone, timeZoneName: timeZoneName.value}))
    const link = computed(() => t('link'))
    const title = computed(() => t('title'))
    const modal = () => {
        open.value = false
        localStorage.setItem(modalTimeZone, 'hidden')
    }
    onMounted(() => {
        const timeZone = localStorage.getItem(modalTimeZone)
        if(timeZone !== 'hidden' && (systemTimeZone !== timeZoneName.value)) {
            open.value = true
        }
    })
</script>