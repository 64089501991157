<template>
  <router-view />
  <Toaster :class="$style.toaster" />
  <ChangeTemplate />
  <InfoAboutTime v-if="profile"/>
</template>

<script setup lang="ts">
import { Toaster } from '@smst/ui'
import { useHead } from '@vueuse/head'
import { computed, onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useQueryClient } from 'vue-query'
// import { VueQueryDevTools } from 'vue-query/devtools'
import { useRoute, useRouter } from 'vue-router'
import InfoAboutTime from '@/components/InfoAboutTime/infoAboutTime.vue'
import { useProfile } from '@/hooks/useProfile';
const { profile } = useProfile()

import { authTokens } from '@/api/authTokens'
import ChangeTemplate from '@/components/ChangeClobalTemplate/ChangeGlobalTemplate.vue'
import { RouteNames } from '@/routeNames'
// import FrontStore from '@/utils/fetchClient/storeFront.vue'

import { setAppLocale } from './utils/locale.utils'

const queryClient = useQueryClient()
const router = useRouter()
const route = useRoute()

const { locale, t } = useI18n()

setAppLocale(locale)
// Действия при разлогине
watch(
  () => authTokens.value,
  (newTokens) => {
    if (!newTokens) {
      queryClient.clear()
      void router.push({ name: RouteNames.Auth })
    }
  }
)

onMounted(() => {
  window.addEventListener('storage', (event) => {
    // if (event.key !== 'now') return
    // console.log(event.key + ':' + event.newValue + ' at ' + event.url)

    if (event?.key === 'auth-tokens') {
      // console.log('here')
      // ProfileData.reloadProfile()
      void router.go(0)
    }
  })
})

const title = computed(() =>
  route.name
    ? t(`meta.title.${String(route.name)}`, t('meta.title.default'))
    : t('meta.title.default')
)

useHead({
  title,
  htmlAttrs: [
    {
      lang: locale,
    },
  ],

  meta: [
    {
      name: 'description',
      content: t('meta.description.default'),
    },
  ],
})
</script>

<style>
#app {
  display: contents;
}
</style>

<style module>
.toaster {
  z-index: var(--z-toaster);
}
</style>
