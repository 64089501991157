import { computed, ref } from 'vue'
import { useMutation } from 'vue-query'

import { apiClient } from '@/api'
import type { Report } from '@/api/__generated__/api.schema'
import { i18n } from '@/i18n'

const { t } = i18n.global

// locale
export const total = computed(() => t(`DeliveryAnalysis.total`))
export const download = computed(() => t(`DeliveryAnalysis.download`))
//

export const idReport = ref(0)

const TableAnalysisData = ref()

export const AnalysisPhones = ref()

export const currentPage = ref(0)
export const totalPages = ref(0)

export const isSuccess = ref(false)
export const loading = ref(false)
export const error = ref()
export const totalItems = ref(0)

export const tableData = computed(() => AnalysisPhones?.value ?? [])

export const showTable = computed(
  () => isSuccess.value && tableData?.value?.length > 0
)
export const getLinkToJournal = (phone: number) => {
  const data = TableAnalysisData.value
  const department =
    data.department.departmentId === -1
      ? ''
      : `&departmentIds=${data.department.departmentId}`

  const link = `/messages-log?from=${data.startDate}+00:00:00&to=${data.endDate}+23:59:59`
  const linkGo = `${link}&page=1&size=10&phones=${phone}${department}`
  return linkGo
}
export const showEmpty = computed(
  () => tableData.value?.length === 0 && isSuccess.value
)
export const showSubTable = computed(() => isSuccess.value)

const getReportQuery = () => {
  return { page: currentPage.value + 1 }
}
const addReportId = (data: Report) => {
  const id = data.reportId
  idReport.value = id

  currentPage.value = 0
  totalPages.value = 0
  totalItems.value = 0
}
const getReportId = () => {
  return idReport.value
}

export const initAnalysisPhones = (loadMore = false) => {
  const { mutate: reportAnalysisPhones } = useMutation(
    async (data) => {
      loading.value = true
      if (!loadMore) {
        TableAnalysisData.value = data
        addReportId(data)
      }
      return await apiClient.deliveryAnalysis.report_GET_BY(
        getReportId(),
        getReportQuery()
      )
    },
    {
      onSuccess: (data) => {
        isSuccess.value = true
        if (loadMore) AnalysisPhones.value.push(...data?.data.list)
        else AnalysisPhones.value = data?.data.list

        currentPage.value = data?.data?.pageInfo?.currentPage
        totalPages.value = data?.data?.pageInfo?.totalPages
        totalItems.value = data?.data?.pageInfo?.totalItems
        loading.value = false
      },
      onError: (err) => {
        error.value = err
        isSuccess.value = false
        loading.value = false
      },
    }
  )

  return { reportAnalysisPhones }
}
