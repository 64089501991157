<template>
  <header :class="$style.header">
    <Text tag="h1">
      {{ t('title') }}
    </Text>

    <Link
      :to="{ name: RouteNames.BatchCreate }"
      view="link"
      :iconLeft="IconPlus"
    >
      {{ t('createBatchLink') }}
    </Link>
  </header>

  <Tabs :items="tabsItems" :class="$style.tabs">
    <template #default="{ item, tabClass, tabActiveClass }">
      <router-link
        :to="{ name: item.value }"
        :class="[
          tabClass,
          [item.value, ...item.children].includes(route.name) && tabActiveClass,
        ]"
      >
        {{ item.label }}
      </router-link>
    </template>
  </Tabs>

  <Filters
    v-model="filtersModelWithoutParentBatchId"
    :defaultValues="defaultFilterValues"
    :controls="filtersControls"
    :class="$style.filters"
  />

  <Controls
    :showTable="showTable"
    :class="$style.parentsBatches"
    :selectionModel="selectionModel"
    :date="request.date"
    :batches="tableData"
    :parentsBatches="parentsBatches"
    :selectedParentBatchId="parentBatch"
    @selectParentBatch="handleParentBatchSelect"
  />

  <template v-if="showTable">
    <LoaderWrapper :loading="showLoaderAboveTable">
      <TableScrollbar>
        <ArchivedBatches
          v-if="isArchive"
          :data="tableData"
          @rowClick="handleRowClick"
        />
        <ScheduledBatchesTable
          v-else
          v-model:modelSelection="selectionModel"
          :data="tableData"
          :defaultOriginator="profile?.defaultOriginator"
          @rowClick="handleRowClick"
        />
      </TableScrollbar>
    </LoaderWrapper>

    <PaginationPanel v-model="paginationModel" :totalItems="totalTableItems" />
  </template>

  <div v-else :class="$style.container">
    <Loader v-if="isBatchesLoading || isParentsBatchesLoading" size="m" />

    <NoResultsFound
      v-if="showNoData || showNoResults"
      :variant="showNoData ? MessageType.noData : MessageType.noResult"
    />

    <ApiErrorMessage :error="batchesError || parentsBatchesError" />
  </div>

  <BatchDrawer :batchId="batchId" :isEdit="isEdit" :isArchive="isArchive" />
</template>

<script lang="ts" setup>
import type { TabsTypes } from '@smst/ui'
import { IconPlus, Loader, LoaderWrapper, Tabs, Text } from '@smst/ui'
import { isDefined, omit } from 'remeda'
import { computed, ref, toRef, watch } from 'vue'
import { useQuery } from 'vue-query'
import { useRoute, useRouter } from 'vue-router'

import { apiClient } from '@/api'
import type { BatchResult } from '@/api/__generated__/api.schema'
import { batchesRefetchInterval } from '@/api/queryConfig'
import ApiErrorMessage from '@/components/ApiErrorMessage.vue'
import Filters from '@/components/Filters/Filters.vue'
import Link from '@/components/Link/Link.vue'
import { MessageType } from '@/components/NoResultsFound/NoResultsFound.types'
import NoResultsFound from '@/components/NoResultsFound/NoResultsFound.vue'
import PaginationPanel from '@/components/PaginationPanel/PaginationPanel.vue'
import TableScrollbar from '@/components/TableScrollbar/TableScrollbar.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { useProfile } from '@/hooks/useProfile'
import { useTableSelection } from '@/hooks/useTable'
import { useTableRequest } from '@/hooks/useTableRequest'
import { RouteNames } from '@/routeNames'
import { hasValues, omitBy } from '@/utils/object'

import {
  filtersConfig,
  getDefaultFilterValues,
  getFiltersControls,
  getParentBatch,
  getQueryToRequest,
} from './Batches.utils'
import ArchivedBatches from './components/ArchivedBatches/ArchivedBatchesTable.vue'
import BatchDrawer from './components/BatchDrawer/BatchDrawer.vue'
import Controls from './components/Controls/Controls.vue'
import ScheduledBatchesTable from './components/ScheduledBatches/ScheduledBatchesTable.vue'

const props = defineProps({
  batchId: {
    type: String,
  },
  isEdit: {
    type: Boolean,
    default: false,
  },
  isArchive: {
    type: Boolean,
    default: false,
  },
})

const { profile } = useProfile()

const t = useComponentI18n('batches')

const tabsItems: Array<TabsTypes.Item & { children: RouteNames[] }> = [
  {
    value: RouteNames.Batches,
    label: t('tabs.scheduled'),
    children: [RouteNames.BatchDetails, RouteNames.BatchEdit],
  },
  {
    value: RouteNames.BatchesArchive,
    label: t('tabs.archived'),
    children: [RouteNames.BatchDetailsArchive],
  },
]

const defaultFilterValues = computed(() =>
  getDefaultFilterValues(props.isArchive)
)

const filtersControls = computed(() => {
  return getFiltersControls(props.isArchive)
})

const { selectionModel, resetSelection } = useTableSelection<number>()

const { request, filtersModel, paginationModel } = useTableRequest({
  filtersConfig,
  sortConfig: {},
})

// Нам нужно иметь parentBatchId в filtersModel для сохранения его значения в адресной строке
// Но в Filters нам нужно передать filtersModel без parentBatchId
const filtersModelWithoutParentBatchId = computed<
  Omit<typeof filtersModel.value, 'parentBatchId'>
>({
  get() {
    return omit(filtersModel.value, ['parentBatchId'])
  },
  set(newValue) {
    filtersModel.value = { ...filtersModel.value, ...newValue }
  },
})

const isFiltersNotEmpty = computed(() =>
  hasValues(filtersModelWithoutParentBatchId.value)
)

/*
  Переменная помогает отличить состояние когда мы обновляем данные с помощью refetchInterval,
  от состояния когда мы обновляем данные из-за изменившихся параметров.
  Таким образом, мы отображаем состояние загрузки только для второго случая.
*/
const isRequestChanged = ref(false)

watch([request, toRef(props, 'isArchive')], () => {
  isRequestChanged.value = true
})

const {
  isLoading: isParentsBatchesLoading,
  isSuccess: isParentsBatchesSuccess,
  isError: isParentsBatchesError,
  data: parentsBatchesData,
  error: parentsBatchesError,
} = useQuery(
  [
    'parentsBatches',
    filtersModelWithoutParentBatchId,
    toRef(props, 'isArchive'),
  ],
  () =>
    apiClient.batches.parentsList_GET({
      ...getQueryToRequest(
        filtersModel.value.date,
        filtersModel.value.status,
        props.isArchive
      ),
      // Значения page и size фиксированные. Запрашиваем сразу весь список
      page: 1,
      size: 20,
    }),
  {
    enabled: computed(() => isFiltersNotEmpty.value),
  }
)

const parentsBatches = computed(() => parentsBatchesData.value?.data.list ?? [])

const parentBatch = computed(() =>
  getParentBatch(filtersModel.value.parentBatchId, parentsBatches.value)
)

const {
  isLoading: isBatchesLoading,
  isSuccess: isBatchesSuccess,
  isFetching: isBatchesFetching,
  isError: isBatchesError,
  data: batchesData,
  error: batchesError,
} = useQuery(
  ['batches', request, toRef(props, 'isArchive'), parentBatch.value],
  () =>
    apiClient.batches.list_GET({
      ...omitBy(
        omit(request.value, ['date', 'status', 'parentBatchId']),
        isDefined
      ),
      ...getQueryToRequest(
        request.value.date,
        request.value.status,
        props.isArchive
      ),
      parentBatchId: parentBatch.value,
    }),
  {
    enabled: computed(
      () => isFiltersNotEmpty.value && isParentsBatchesSuccess.value
    ),
    refetchInterval: computed(() =>
      props.isArchive ? false : batchesRefetchInterval
    ),
    refetchIntervalInBackground: false,
    keepPreviousData: true,
    onSuccess: () => {
      if (isRequestChanged.value) {
        isRequestChanged.value = false
        resetSelection()
      }
    },
  }
)

const tableData = computed(() => batchesData.value?.data.list ?? [])
const totalTableItems = computed(
  () => batchesData.value?.data.pageInfo?.totalItems ?? 0
)

const showTable = computed(
  () =>
    isBatchesSuccess.value &&
    isParentsBatchesSuccess.value &&
    tableData?.value?.length > 0
)
const showNoData = computed(
  () =>
    !isFiltersNotEmpty.value &&
    !isBatchesError.value &&
    !isParentsBatchesError.value
)
const showNoResults = computed(
  () =>
    isBatchesSuccess.value &&
    isParentsBatchesSuccess.value &&
    tableData?.value?.length === 0
)

const handleParentBatchSelect = (parentBatchId: number) => {
  filtersModel.value = {
    ...filtersModel.value,
    parentBatchId,
  }
}

const showLoaderAboveTable = computed(
  () => isBatchesFetching.value && isRequestChanged.value
)

const router = useRouter()
const route = useRoute()

const handleRowClick = (item: BatchResult) => {
  const name = props.isArchive
    ? RouteNames.BatchDetailsArchive
    : RouteNames.BatchDetails

  void router.push({
    ...route,
    name,
    params: { batchId: item.batchId },
  })
}
</script>

<style module>
.header {
  display: flex;
  align-items: baseline;
  margin-bottom: var(--gap-24);

  column-gap: var(--gap-32);
}

.tabs {
  align-self: flex-start;
  margin-bottom: var(--gap-32);
}

.filters {
  margin-bottom: var(--gap-12);
}

.parentsBatches {
  flex-direction: row;
  margin-bottom: var(--gap-16);

  /* overflow-x: auto; */
}

.container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
</style>
