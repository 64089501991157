<template>
  <div :name="name" :class="fallthroughClass">
    <div :class="$style.charset">
      <Select
        v-model="charset"
        :options="charsetOptions"
        :label="t('charset.label')"
        :canDeselect="false"
        :searchable="false"
      />
    </div>

    <FileInput
      v-model="file"
      v-bind="fallthroughAttrs"
      :locale="fileProps.locale"
      :accept="fileProps.accept"
      :maxSize="fileProps.maxSize"
    >
      <template
        v-if="fileProps.descriptions && fileProps.descriptions?.length > 0"
      >
        <Text
          v-for="description in fileProps.descriptions"
          :key="description"
          tag="p"
          color="secondary"
        >
          {{ description }}
        </Text>
      </template>
    </FileInput>

    <Text
      v-if="errorMessage && meta.touched"
      color="error"
      size="s"
      weight="medium"
      :class="$style.error"
    >
      {{ errorMessage }}
    </Text>
    <FormShowErrorsPhones v-if="responseData" :responseData="responseData" />
    <div v-if="value.isLoading" :class="$style.loader">
      <Loader />
      <Text>
        {{ t('file.loading') }}
      </Text>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { FileInput, Loader, Select, Text, toaster } from '@smst/ui'
import { useField, useFieldError } from 'vee-validate'
import { computed, ref, useAttrs, watchEffect } from 'vue'
import type { PropType } from 'vue'
import { useMutation } from 'vue-query'

import { apiClient } from '@/api'
import type { PhonesTypeFile } from '@/api/__generated__/api.schema'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { getErrorMessage } from '@/utils/errors'

import type { File as FileField } from '../BatchForm/BatchForm.utils'

import FormShowErrorsPhones from './FormShowErrorsTable.vue'
import { useFileInputProps } from './FormUploadPhones.utils'

type Value = {
  file: File
  phonesType: PhonesTypeFile
  charset: 'windows-1251' | 'utf-8'
}

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  type: {
    type: String as PropType<PhonesTypeFile>,
    required: true,
  },
})

const emit = defineEmits(['success'])
const responseData = ref()

const resetErrors = () => {
  responseData.value = undefined
}

const initialValue = {
  name: '',
  isLoading: false,
  phonesCount: 0,
}

const { value, handleChange, meta } = useField<FileField>(
  props.name,
  undefined,
  {
    initialValue,
  }
)

const errorMessage = useFieldError(`${props.name}.name`)

const attrs = useAttrs()
const t = useComponentI18n(
  computed(() => `batchCreate.form.phonesSource.blocks.${props.type}`)
)

const charsetOptions = [
  { value: 'windows-1251', label: 'windows-1251' },
  { value: 'utf-8', label: 'UTF-8' },
]

const file = ref<File | undefined>()
const charset = ref<string>(charsetOptions[0].value)

const { class: fallthroughClass, ...fallthroughAttrs } = attrs

const fileProps = computed(() => useFileInputProps(props.type))

const { mutate: uploadFile } = useMutation(
  (values: Value) => apiClient.files.subscribersUpload_POST(values),
  {
    onMutate: () => {
      handleChange({ name: '', isLoading: true })
    },
    onSuccess: (response) => {
      responseData.value = response.data.data
      handleChange({
        name: response.data.data?.fileName,
        isLoading: false,
        phonesCount: response.data.data?.successRows,
      })
      emit('success', response.data.data)
    },
    onError: (e) => {
      toaster.error(getErrorMessage(e))
      file.value = undefined
    },
  }
)

watchEffect(() => {
  if (file.value) {
    const formData = new FormData()
    formData.set('file', file.value)
    formData.set('phonesType', props.type)
    formData.set('charset', charset.value)

    uploadFile(formData as unknown as Value)
  } else {
    handleChange(initialValue)
    resetErrors()
  }
})
</script>

<style module>
.charset {
  margin-bottom: var(--gap-16);
}

.loader {
  display: flex;
  gap: var(--gap-8);
  margin-top: var(--gap-16);
}

.error {
  display: inline-flex;
  margin-top: var(--gap-8);
}
</style>
