import type { BatchChannelTemplate } from '@/api/__generated__/api.schema'
import { i18n } from '@/i18n'
import type { Channel } from '@/pages/BatchCreate/components/BatchForm/BatchForm.utils'
import { channelsLimits } from '@/pages/BatchCreate/components/BatchForm/BatchForm.utils'
import { checkboxToBoolean } from '@/utils/checkbox'
import { basicEntityNameLength, originatorOptions } from '@/utils/validation'
import yup from '@/utils/yup'

import type { FormValues } from './EditForm.utils'

const { t } = i18n.global

export const editFormSchema: yup.SchemaOf<FormValues> = yup.object({
  name: yup
    .string()
    .min(
      basicEntityNameLength.min,
      t('validation.string.min', { number: basicEntityNameLength.min })
    )
    .max(
      basicEntityNameLength.max,
      t('validation.string.max', { number: basicEntityNameLength.max })
    )
    .requiredField('batches.edit.form.name.label'),
  originator: yup
    .string()
    .min(
      originatorOptions.minLength,
      t('validation.string.min', { number: originatorOptions.minLength })
    )
    .max(
      originatorOptions.maxLength,
      t('validation.string.max', { number: originatorOptions.maxLength })
    )
    .matches(
      originatorOptions.regExp,
      t('batchCreate.form.message.originator.validation')
    )
    .requiredField('batches.edit.form.originator.label'),
  startDate: yup.string().required(),
  isLocalTime: yup.array().of(yup.boolean()).default([]),
  suspend: yup.array().of(yup.boolean()).default([]),
  endTime: yup
    .string()
    .default('')
    .when(['suspend', 'startTimeNextDay'], {
      is: (suspend: boolean[]) => checkboxToBoolean(suspend),
      then: (schema) =>
        schema.requiredField('batches.edit.validation.timeInputLabel'),
      otherwise: (schema) => schema.optional(),
    })
    .test({
      test(time) {
        if (!time) {
          return true
        }

        const hasAllTimeUnits =
          time.split(':').filter((part) => part?.length === 2)?.length === 3

        return hasAllTimeUnits
          ? true
          : this.createError({
              message: t('batches.edit.validation.timeFormat'),
            })
      },
    }),
  startTimeNextDay: yup
    .string()
    .default('')
    .test({
      test(startTime) {
        const { endTime } = this.parent
        if (!endTime || !startTime) {
          return true
        }

        return endTime !== startTime
          ? true
          : this.createError({
              message: t('batches.edit.validation.sameStopAndStartTime'),
            })
      },
    })
    .test({
      test(time) {
        if (!time) {
          return true
        }

        const hasAllTimeUnits =
          time.split(':').filter((part) => part?.length === 2)?.length === 3

        return hasAllTimeUnits
          ? true
          : this.createError({
              message: t('batches.edit.validation.timeFormat'),
            })
      },
    }),
  gap: yup.number().required(),
  timeout: yup.number().required(),
  rus: yup.array().of(yup.boolean()).default([]),
  channels: yup.object().test({
    test(channels: BatchChannelTemplate | undefined) {
      if (!channels) {
        return true
      }

      for (const channel in channels) {
        if (channel in channels) {
          const { message, buttonName, buttonUrl } = channels[channel]
          const messageLength = message?.length ?? 0
          const maxMessageLength = channelsLimits?.[channel as Channel] ?? 0

          if (messageLength === 0) {
            return this.createError({
              path: `channels.${channel}.message`,
              message: t('validation.string.min', { number: 1 }),
            })
          }

          if (messageLength > maxMessageLength) {
            return this.createError({
              path: `channels.${channel}.message`,
              message: t('validation.string.max', { number: maxMessageLength }),
            })
          }

          if (buttonName && !buttonUrl) {
            if (buttonName?.length > 255) {
              return this.createError({
                path: `channels.${channel}.buttonName`,
                message: t('validation.string.max', { number: 255 }),
              })
            }

            return this.createError({
              path: `channels.${channel}.buttonUrl`,
              message: t('validation.required', {
                name: t('batches.edit.form.channels.buttonUrl.label'),
              }),
            })
          }

          if (!buttonName && buttonUrl) {
            if (buttonUrl?.length > 255) {
              return this.createError({
                path: `channels.${channel}.buttonUrl`,
                message: t('validation.string.max', { number: 255 }),
              })
            }

            return this.createError({
              path: `channels.${channel}.buttonName`,
              message: t('validation.required', {
                name: t('batches.edit.form.channels.buttonName.label'),
              }),
            })
          }
        }
      }

      return true
    },
  }),
  message: yup
    .string()
    .min(1, t('validation.string.min', { number: 1 }))
    .max(2048, t('validation.string.max', { number: 2048 })),
})
