import { isDefined } from 'remeda'
import { computed, ref } from 'vue'
import { useQuery } from 'vue-query'

import { apiClient } from '@/api'
import { useTableRequest } from '@/hooks/useTableRequest'
import { omitBy } from '@/utils/object'

export const initAnalysis = () => {
  const { request, paginationModel } = useTableRequest({
    filtersConfig: {},
    sortConfig: {},
  })

  const {
    data,
    isSuccess: isSuccess,
    isLoading: isLoading,
    isFetching: isFetching,
    error: error,
  } = useQuery(
    ['analysisTable', request],
    () => {
      return apiClient.deliveryAnalysis.reports_GET({
        ...omitBy(request.value, isDefined),
      })
    },
    {
      keepPreviousData: false,
    }
  )

  const tableData = computed(() => data.value?.data.list ?? [])
  const showTable = ref(true)

  const totalTableItems = computed(
    () => data.value?.data.pageInfo?.totalItems ?? 0
  )
  const showEmpty = computed(
    () => tableData.value?.length === 0 && isSuccess.value
  )
  return {
    paginationModel,
    showEmpty,
    totalTableItems,
    showTable,
    tableData,
    data,
    isSuccess,
    isLoading,
    isFetching,
    error,
  }
}
