<template>
  <LoaderWrapper v-if="showTable" :loading="showLoaderAboveTable">
    <Text tag="p" :class="$style.description" size="l" weight="semibold">
      {{ t('title') }}
      {{ departmentData.name }}
    </Text>

    <AccountsTable
      :data="tableData"
      @edit="handleEdit"
      @unblock="handleUnblock"
      @delete="openDeleteConfirmation"
    />
  </LoaderWrapper>

  <div v-else :class="$style.container">
    <NoResultsFound
      v-if="showNoResultsFound"
      :customVariant="{
        icon: IconAddUser,
        iconSize: 'l',
        title: $t('accountsAndDepartments.noAccounts'),
      }"
    />
  </div>

  <Modal
    v-model="isDeleteConfirmationOpen"
    :title="tModal('accounts.title', { name: depUserShortDataToDelete?.name })"
    :acceptButton="tModal('acceptButton')"
    :cancelButton="tModal('cancelButton')"
    @accept="handleDelete"
  />
</template>

<script lang="ts" setup>
import { IconAddUser, LoaderWrapper, Modal, Text, toaster } from '@smst/ui'
import { computed, ref } from 'vue'
import type { PropType } from 'vue'
import { useMutation, useQueryClient } from 'vue-query'
import { useRouter } from 'vue-router'

import { apiClient } from '@/api'
import type { DepartmentResult } from '@/api/__generated__/api.schema'
import AccountsTable from '@/components/AccountsTable/AccountsTable.vue'
import NoResultsFound from '@/components/NoResultsFound/NoResultsFound.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { RouteNames } from '@/routeNames'
import { getErrorMessage } from '@/utils/errors'

type DepUserShortData = { id: number; name: string }
type DepUserShortDataWithPayload = DepUserShortData & {
  data: { status: 'active' }
}

const props = defineProps({
  departmentData: {
    type: Object as PropType<DepartmentResult>,
    required: true,
  },
  isDepartmentDataFetching: Boolean,
})

const t = useComponentI18n('editDepartment.sections.accounts')
const tToasts = useComponentI18n('accountsAndDepartments.toasts.accounts')
const tModal = useComponentI18n('accountsAndDepartments.modal')

const isDeleteConfirmationOpen = ref(false)
const depUserShortDataToDelete = ref<DepUserShortData | undefined>(undefined)

const router = useRouter()

const tableData = computed(() => props.departmentData.users)

const showTable = computed(() => tableData.value?.length > 0)

const showNoResultsFound = computed(() => tableData.value?.length === 0)

const queryClient = useQueryClient()

const departmentQueryKey = ['department', String(props.departmentData.id)]

const { mutate: deleteDepUser, isLoading: isDeleteDepUserLoading } =
  useMutation(
    async ({ id }: DepUserShortData) => {
      await apiClient.departments.users_DELETE(props.departmentData.id, id)
    },
    {
      onSuccess: (_, { name }) => {
        void queryClient.invalidateQueries(departmentQueryKey)
        toaster.success(tToasts('successDelete', { name }))
      },
      onError: (error) => {
        toaster.error(getErrorMessage(error))
      },
    }
  )

const openDeleteConfirmation = (data: DepUserShortData) => {
  isDeleteConfirmationOpen.value = true
  depUserShortDataToDelete.value = data
}

const handleDelete = () => {
  if (depUserShortDataToDelete.value) {
    deleteDepUser(depUserShortDataToDelete.value)

    isDeleteConfirmationOpen.value = false
    depUserShortDataToDelete.value = undefined
  }
}

const { mutate: editDepUser, isLoading: isEditDepUserLoading } = useMutation(
  async ({ id, data }: DepUserShortDataWithPayload) => {
    await apiClient.departments.users_PUT(props.departmentData.id, id, data)
  },
  {
    onSuccess: (_, { name }) => {
      void queryClient.invalidateQueries(departmentQueryKey)
      toaster.success(tToasts('successUnblock', { name }))
    },
    onError: (error) => {
      toaster.error(getErrorMessage(error))
    },
  }
)

const handleUnblock = ({ id, name }: DepUserShortData) => {
  editDepUser({ id, name, data: { status: 'active' } })
}

const showLoaderAboveTable = computed(
  () =>
    props.isDepartmentDataFetching ||
    isDeleteDepUserLoading.value ||
    isEditDepUserLoading.value
)

const handleEdit = (id: number) => {
  void router.push({
    name: RouteNames.DepartmentAccountSettings,
    params: { accountId: id },
  })
}
</script>

<style module>
.description {
  margin-bottom: var(--gap-12);
}

.container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
</style>
