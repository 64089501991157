import * as Sentry from '@sentry/vue'
import type { App } from 'vue'

const dsn = import.meta.env.VITE_SENTRY_DNS_PUBLIC_KEY as string

const mainHost = location.hostname
//http://sentry.smst.me/api/25/security/?sentry_key=bb3ee6838eda4b2186af2bcc283c1147
export const SentryInit = (app: App<Element>, router) => {
  if (dsn && mainHost !== 'localhost') {
    return Sentry.init({
      app,
      dsn: `https://${dsn}@${mainHost}/25`,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
        Sentry.browserApiErrorsIntegration({
          setTimeout: true,
          setInterval: true,
          requestAnimationFrame: true,
          XMLHttpRequest: true,
          eventTarget: true,
        }),
        Sentry.captureConsoleIntegration(),
        Sentry.httpContextIntegration(),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.1,
      //https://api.smstraffic.ru/dev
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/api.smstraffic\.ru\/dev/,
      ],
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
    })
  }
}
