<template>
  <Row>
    <Card :title="t('roles.title')">
      <div
        v-for="(role, index) in rolesListSort"
        :key="index"
        :class="$style.roles"
      >
        <FormSwitch
          v-if="showFormSwitch(role.id)"
          name="roles"
          :value="role.id"
          :disabled="isRoleDisabled(role.id, formValues.roles)"
          fullWidth
        >
          <span
            v-if="getRoleHint(role.id)"
            :title="getRoleHint(role.id)"
            :class="$style.roleDescriptionWithHint"
          >
            {{ getRoleDescription(role.description) }}
            <IconInfo :class="$style.roleDescriptionHintIcon" />
          </span>

          <template v-else>
            {{ getRoleDescription(role.description) }}
          </template>
        </FormSwitch>
        <FormCheckbox
          v-if="showCheckBox(role.id)"
          :value="role.id"
          name="roles"
          fullWidth
          :class="{ [$style.disable]: disableCheck(role.id) }"
          :disabled="disableCheck(role.id)"
        >
          <span
            v-if="getRoleHint(role.id)"
            :title="getRoleHint(role.id)"
            :class="$style.roleDescriptionWithHint"
          >
            {{ getRoleDescription(role.description) }}
            <IconInfo :class="$style.roleDescriptionHintIcon" />
          </span>

          <template v-else>
            {{ getRoleDescription(role.description) }}
          </template>
        </FormCheckbox>
      </div>
    </Card>
  </Row>
</template>

<script lang="ts" setup>
import { IconInfo } from '@smst/ui'
import { difference } from 'remeda'
import { computed, watch } from 'vue'
import type { PropType } from 'vue'
import { useI18n } from 'vue-i18n'

import type {
  AccountFullResult,
  DepUserFullResult,
  RoleResult,
} from '@/api/__generated__/api.schema'
import Card from '@/components/Card/Card.vue'
import FormCheckbox from '@/components/FormCheckbox/FormCheckbox.vue'
import FormSwitch from '@/components/FormSwitch/FormSwitch.vue'
import { Row } from '@/components/Grid'
import { useComponentI18n } from '@/hooks/useComponentI18n'

import {
  getRoleHint,
  getRolesToDeselect,
  isRoleDisabled,
  rolesSort,
  showCheckBox,
  showFormSwitch,
} from './AccountForm.utils'

const props = defineProps({
  accountData: Object as PropType<AccountFullResult | DepUserFullResult>,
  formValues: { type: Object, required: true },
  setFieldValue: { type: Function, required: true },
  rolesList: {
    type: Array as PropType<RoleResult[]>,
    required: true,
  },
})

const t = useComponentI18n('accountForm')

const disableCheck = (id: number) => {
  const sms = props.formValues.roles.includes(2)
  const messages = props.formValues.roles.includes(3)
  const noText = props.formValues.roles.includes(7)
  const noNumbers = props.formValues.roles.includes(8)
  if (id === 8 && noText) {
    return true
  }
  if (id === 7 && noNumbers) {
    return true
  }
  if ((id === 9 && sms) || (id === 7 && messages) || (id === 8 && messages)) {
    return false
  }
  return true
}

watch(
  () => props.formValues.roles,
  (newSelectedRoles = [], oldSelectedRoles = []) => {
    const deselectedRoles = difference(oldSelectedRoles, newSelectedRoles)

    const rolesToDeselect = getRolesToDeselect(deselectedRoles)

    if (rolesToDeselect?.length > 0) {
      props.setFieldValue(
        'roles',
        newSelectedRoles.filter((i) => !rolesToDeselect.includes(i))
      )
    }
  }
)

const rolesListSort = computed(() => {
  return rolesSort(props.rolesList)
})

const { locale, fallbackLocale } = useI18n()

const getRoleDescription = (description: Record<string, string>) => {
  const defaultLocaleKey = fallbackLocale.value as string

  return description[locale.value] || description[defaultLocaleKey]
}
</script>

<style module>
.description {
  margin-bottom: var(--gap-16);
}

.passwordBlock {
  & + & {
    margin-top: var(--gap-24);
  }
}

.timezoneBlock {
  display: grid;
  row-gap: var(--gap-16);
}

.roles {
  & + & {
    margin-top: var(--gap-16);
  }
}

.disable {
  opacity: 0.5;
}

.roleDescriptionWithHint {
  display: inline-flex;
  align-items: center;
}

.roleDescriptionHintIcon {
  margin-left: var(--gap-4);
}

.errors {
  margin-top: var(--gap-4);

  color: var(--color-error);
}

.errorContainer {
  margin-top: var(--gap-32);
}

.submit {
  margin-top: var(--gap-32);
}
</style>
