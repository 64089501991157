import type { TableTypes } from '@smst/ui'
import { parseISO } from 'date-fns'
import { isNumber, sortBy } from 'remeda'

import type { DepartmentResult } from '@/api/__generated__/api.schema'
import { getTwoFactorAuthStatus } from '@/utils/getTwoFactorAuthStatus'

export type SortableDepartmentFields =
  | 'name'
  | 'status'
  | 'addDate'
  | 'twoFactorAuth'
  | 'users'

export type SortableDepartment = Pick<
  DepartmentResult,
  SortableDepartmentFields
>

export const getSortedData = (
  data: DepartmentResult[],
  prop: SortableDepartmentFields,
  direction: TableTypes.SortDirection
) =>
  sortBy(data, [
    (item) => {
      if (prop === 'addDate') {
        const date = parseISO(item[prop])

        return date.getTime()
      }

      if (prop === 'twoFactorAuth') {
        return isNumber(getTwoFactorAuthStatus(item[prop]))
      }

      if (prop === 'users') {
        return item[prop]?.length
      }

      return item[prop]
    },
    direction,
  ])
